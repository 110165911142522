/* eslint-disable */
.home {
    display: block;
    font-family: 'Press Start 2P', monospace;
}

.home.hide {
    animation: slide-left 1s linear;
}

@keyframes slide-left {
    0% {
        transform: translateX(0);
    }
    99% {
        transform: translateX(-100%);
    }
    100% {
        display: none;
    }
}

.blink-infinite {
    animation: blink-inf 1s infinite;
    animation-delay: 0.5s;
}

@keyframes blink-inf {
    0% {
        opacity: 1;
    }
    49% {
        opacity: 1;
    }
    50% {
        opacity: 0;
    }
    99% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}