.hiddencard {
    margin-top: 5px;
    transform: translateY(-100vh);
    opacity: 0;
    transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;
}

.visiblecard {
    margin-top: 5px;
    transform: translateY(0);
    opacity: 1;
    transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;
}

.heading {
    font-family: 'Press Start 2P', cursive;
    color: #0154b4;
    text-align: center;
    margin: 25px;
}
