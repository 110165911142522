.guy-7{

}
.parachute-7{
    opacity: 0;
}
.cap-7{
    opacity: 0;
}
.book-7{
    opacity: 0;
}
.git-logo-7{
    animation: drop-on-head 4s linear forwards;
}
@keyframes drop-on-head {
    0%{
        transform: translateY(-300%) rotate(0deg);
        opacity: 0;
    }
    25%{
        transform: translateY(-200%) rotate(15deg);
        opacity: 1;
    }
    50%{
        transform: translateY(-100%) rotate(30deg);
        opacity: 1;
    }
    60%{
        transform: translateY(-100%) translateX(50%) rotate(90deg);
        opacity: 1;
    }
    70%{
        transform: translateY(-50%) translateX(100%) rotate(180deg);
        opacity: 1;
    }
    80%{
        transform: translateX(150%) rotate(270deg);
        opacity: 1;
    }
    90%{
        transform: translateX(200%) rotate(330deg);
        opacity: 1;
    }
    99%{
        transform: translateX(252%) rotate(362deg);
        opacity: 1;
    }
    100%{
        transform: translateX(250%) rotate(360deg);
        opacity: 1;
    }
}