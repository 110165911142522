.guy-5{
    animation: jump-forward 700ms linear forwards;
    animation-iteration-count: 7;
    transform-origin: bottom;
}
.parachute-5{
    opacity: 0;
}
.cap-5{
    opacity: 0;
}
.book-5{
    opacity: 0;
}
.git-logo-5{
    opacity: 0;
}