.guy-2{

}
.parachute-2{
    opacity: 0;
}
.cap-2{
    animation: fadeOut 5s linear forwards;
}
.book-2{
    opacity: 0;
}
.git-logo-2{
    opacity: 0;
}
@keyframes fadeOut {
    0%{
        opacity: 1;
    }
    90%{
        opacity: 1;
    }
    100%{
        opacity: 0;
    }
}
@keyframes go-left {
    0%{
        transform: translateX(0);
    }
    50%{
        transform: translateX(-60%);
        opacity: 1;
    }
    100%{
        transform: translateX(-120%);
        opacity: 0;
    }
}
