
.guy-3{
    animation: high-jump 1.15s linear infinite;
    animation-iteration-count: 3;
    transform-origin: top;
}
.parachute-3{
    opacity: 0;
}
.cap-3{
    opacity: 0;
}
.book-3{
    opacity: 0;
}
.git-logo-3{
    opacity: 0;
}
@keyframes high-jump {
    0% {
        transform: rotate(0deg);
    }
    25% {
        transform: translateY(-100%) rotate(12deg);
    }
    50% {
        transform: translateY(-200%) rotate(0deg);
    }
    75% {
        transform: translateY(-100%) rotate(-12deg);
    }
    100% {
        transform: translateY(0) rotate(0deg);
    }
}