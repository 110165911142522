.guy-6{

}
.parachute-6{
    opacity: 0;
}
.cap-6{
    opacity: 0;
}
.book-6{
    opacity: 0;
}
.git-logo-6{
    opacity: 0;
}