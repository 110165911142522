.glare {
    position: absolute;
    top: 6px;
    left: 3px;
    width: 3px;
    height: 6px;
    border-radius: 50%;
    background: white;
    opacity: 0.5;
    box-shadow: 0 0 4px 3px white;
}

.circle {
    animation: glow_out 0.5s ease-in-out forwards;
}

.circle:hover {
    animation: glow_in 0.5s ease-in-out forwards;
}

@keyframes glow_out {
    0% {
        box-shadow: 0 0 3px 3px rgba(255, 255, 255, 0.3) inset;
    }
    100% {
        box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.7) inset;
    }
}

@keyframes glow_in {
    0% {
        box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.7) inset;
    }
    100% {
        box-shadow: 0 0 3px 3px rgba(255, 255, 255, 0.3) inset;
    }
}

.spawn {
    animation: spawn 0.25s linear forwards;
}

.pop {
    animation: pop 0.1s linear forwards;
}

@keyframes spawn {
    0% {
        transform: translateY(-120%);
        opacity: 0;
    }
    100% {
        transform: translateY(0);
        opacity: 1;
    }
}

@keyframes pop {
    0% {
        transform: scale(1);
        opacity: 1;
    }
    100% {
        transform: scale(1.2);
        opacity: 0;
    }
}
