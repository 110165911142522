.guy-4{

}
.parachute-4{
    opacity: 0;
}
.cap-4{
    opacity: 0;
}
.book-4{
    opacity: 0;
}
.git-logo-4{
    opacity: 0;
}