.platform {
    width: 101vw;
    height: 50px;
    background-image: url('texture.svg');
    background-repeat: repeat;
    overflow: hidden;
}

.platform.moving {
    animation: slide linear infinite;
    overflow: hidden;
}

@keyframes slide {
    100% {
        transform: translateX(-40px)
    }
}

