.framework{
    opacity: 0;
    animation: drop-roll 5s linear forwards;
}
@keyframes drop-roll {
    0%{
        transform: translateY(-100%) translateX(300%) rotate(0deg);
        opacity: 0;
    }
    25%{
        transform: translateY(0) translateX(250%) rotate(-90deg);
        opacity: 1;
    }
    27%{
        transform: translateY(-5px) translateX(240%) rotate(-100deg);
        opacity: 1;
    }
    35%{
        transform: translateY(0) translateX(200%) rotate(-120deg);
        opacity: 1;
    }
    50%{
        transform: translateX(150%) rotate(-180deg);
        opacity: 1;
    }
    100%{
        transform: translateX(0) rotate(-360deg);
        opacity: 0;
    }

}