.page {
    top: 50px;
    bottom: 100px;
    align-items: center;
    justify-content: center;
    width: 95vw;
    padding: 20px;
    margin: 10px;
    border-radius: 10px;
    box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
    cursor: pointer;
    backdrop-filter: blur(1px);
    background-color: rgba(255, 255, 255, 0.05);
}

.page::-webkit-scrollbar {
    width: 20px;
}

/* Track */
.page::-webkit-scrollbar-track {
    margin: 5px;
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
}

/* Handle */
.page::-webkit-scrollbar-thumb {
    background: rgba(255, 255, 255, 0.10);
    border-radius: 10px;
}

/* Handle on hover */
.page::-webkit-scrollbar-thumb:hover {
    background: rgba(255, 255, 255, 0.20);
}

.showpage {
    opacity: 1;
    transition: opacity 0.5s;
}

.hidepage {
    opacity: 0;
    transition: opacity 0.5s;
}

.markdown {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-bottom: 10px;
    justify-content: center;
    color: #ffbc62;
    overflow: visible;
}

.markdown-container {
    border-radius: 10px;
    box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
    height: 100%;
    width: 100%;
    margin: 10px;
    padding: 0 10px;
    overflow: auto;
    cursor: pointer;
    backdrop-filter: blur(1px);
    background-color: rgba(255, 255, 255, 0.05);
}

.markdown-container::-webkit-scrollbar {
    width: 20px;
}

/* Track */
.markdown-container::-webkit-scrollbar-track {
    margin: 5px;
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
}

/* Handle */
.markdown-container::-webkit-scrollbar-thumb {
    background: rgba(255, 255, 255, 0.10);
    border-radius: 10px;
}

/* Handle on hover */
.markdown-container::-webkit-scrollbar-thumb:hover {
    background: rgba(255, 255, 255, 0.20);
}

.page-heading {
    font-size: 25px;
    font-family: cursive;
    color: #FF9506;
    margin: 10px 25px;
    width: fit-content;
}

.page-content {
    font-size: 16px;
    color: #FF9506;
    margin: 10px;
    width: fit-content;
}

.input-glass {
    display: inline-block;
    background: linear-gradient(45deg, rgba(255, 255, 255, 0), rgba(248, 248, 248, 0.15));
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
    border-radius: 20px;
    padding: 10px 20px;
    font-size: 15px;
    font-weight: bold;
    text-decoration: none;
    color: #58beff;
    transition: all 0.2s ease-in-out;
}

.button-glass {
    display: inline-block;
    background: linear-gradient(45deg, rgba(255, 255, 255, 0), rgba(248, 248, 248, 0.15));
    border: 1px solid #ccc;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
    border-radius: 20px;
    padding: 10px 20px;
    font-size: 15px;
    font-weight: bold;
    text-decoration: none;
    color: #58beff;
    transition: all 0.2s ease-in-out;
    cursor: pointer;
}

.button-glass:hover {
    background: linear-gradient(45deg, rgba(255, 255, 255, 0.05), rgba(248, 248, 248, 0.20));
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.5);
    color: #9cceff;
    transform: translateY(-2px);
}

.text {
    display: flex;
    flex-direction: row;
    justify-content: center;
    font-size: 16px;
    color: #FF9506;
    margin: 10px 25px;
    width: fit-content;
}

.center {
    display: flex;
    flex-wrap: wrap;
    padding: 15px;
    justify-content: center;
    align-content: center;
    align-items: baseline;
    color: #fddcb0;
    font-family: 'Palatino Linotype', serif;
}